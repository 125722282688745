import { default as _91id_93LPbR7UXA8VMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93Qh6tVPyNnMMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as publicxSne69SlL2Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as indexrsBL1Q2hi4Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenanceDcall3RnNkMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandboxMp7V9Crym6Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinEKEpixjFSJMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signout3BLLvTiLYeMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signup9na8osuJHFMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as indexyp2nRaT5RMMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessionsPcWZo2X8f0Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analyticspad5ACvqHpMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_93nlJO2R2l1YMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as create85JI3NMTtXMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_93v3FHAUd0hxMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as indexNo7HHw5ojOMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentsbpm41Waoa8Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as indexfsARB7Xh68Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutFMTgrVHpauMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPasswordlE4At7Ad7UMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as indexupv3N7YOt5Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationsYUmQr4U1QCMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legalgvG2U1JAn8Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organizationPGdHs0CI2CMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planH5ca9M7f77Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsoXYvVEU02mMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as support9uafatY691Meta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamYVPyplXZBdMeta } from "/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: "chat-id___en",
    path: "/chat/:id()",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-id___de",
    path: "/de/chat/:id()",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___en",
    path: "/chat/embed/:id()",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___de",
    path: "/de/chat/embed/:id()",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-public___en",
    path: "/chat/public",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "chat-public___de",
    path: "/de/chat/public",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/de/maintenance",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___en",
    path: "/sandbox",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___de",
    path: "/de/sandbox",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/signin",
    meta: signinEKEpixjFSJMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de",
    path: "/de/signin",
    meta: signinEKEpixjFSJMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signout___en",
    path: "/signout",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signout___de",
    path: "/de/signout",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signup9na8osuJHFMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: signup9na8osuJHFMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analyticspad5ACvqHpMeta?.name,
    path: "/user/analytics",
    meta: analyticspad5ACvqHpMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___en",
    path: "",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___en",
    path: "sessions",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analyticspad5ACvqHpMeta?.name,
    path: "/de/user/analytics",
    meta: analyticspad5ACvqHpMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___de",
    path: "",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___de",
    path: "sessions",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-chatbots-chat-id___en",
    path: "/user/chatbots/chat/:id()",
    meta: _91id_93nlJO2R2l1YMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-chat-id___de",
    path: "/de/user/chatbots/chat/:id()",
    meta: _91id_93nlJO2R2l1YMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___en",
    path: "/user/chatbots/create",
    meta: create85JI3NMTtXMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___de",
    path: "/de/user/chatbots/create",
    meta: create85JI3NMTtXMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___en",
    path: "/user/chatbots/edit/:id()",
    meta: _91id_93v3FHAUd0hxMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___de",
    path: "/de/user/chatbots/edit/:id()",
    meta: _91id_93v3FHAUd0hxMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___en",
    path: "/user/chatbots",
    meta: indexNo7HHw5ojOMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___de",
    path: "/de/user/chatbots",
    meta: indexNo7HHw5ojOMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___en",
    path: "/user/documents",
    meta: documentsbpm41Waoa8Meta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___de",
    path: "/de/user/documents",
    meta: documentsbpm41Waoa8Meta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/user",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "/de/user",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___en",
    path: "/user/postcheckout",
    meta: postcheckoutFMTgrVHpauMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___de",
    path: "/de/user/postcheckout",
    meta: postcheckoutFMTgrVHpauMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___en",
    path: "/user/resetPassword",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___de",
    path: "/de/user/resetPassword",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: settingsoXYvVEU02mMeta?.name,
    path: "/user/settings",
    meta: settingsoXYvVEU02mMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___en",
    path: "",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___en",
    path: "integrations",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___en",
    path: "legal",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___en",
    path: "organization",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsoXYvVEU02mMeta?.name,
    path: "/de/user/settings",
    meta: settingsoXYvVEU02mMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings___de",
    path: "",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___de",
    path: "integrations",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___de",
    path: "legal",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___de",
    path: "organization",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___de",
    path: "plan",
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-support___en",
    path: "/user/support",
    meta: support9uafatY691Meta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-support___de",
    path: "/de/user/support",
    meta: support9uafatY691Meta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-team___en",
    path: "/user/team",
    meta: teamYVPyplXZBdMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  },
  {
    name: "user-team___de",
    path: "/de/user/team",
    meta: teamYVPyplXZBdMeta || {},
    component: () => import("/codebuild/output/src235296864/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]