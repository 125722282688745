<script setup lang="ts">
import { provideUseId } from "@headlessui/vue";

provideUseId(() => useId());

const { appUrl, maintenance, language } = useRuntimeConfig().public;

// Maintenance mode
if (useRoute().path !== "/maintenance" && maintenance.enabled)
  await navigateTo("/maintenance");

// Set language
const { availableLocales, setLocale } = useI18n();
if (language && availableLocales.includes(language.toLowerCase()))
  await setLocale(language.toLowerCase());
else
  await setLocale("en");

// Script for LS
useHead({
  script: [
    {
      src: "https://lmsqueezy.com/affiliate.js",
      type: "text/javascript",
      crossorigin: "anonymous",
      defer: true,
      onload() {
        // @ts-expect-error lemonSqueezyAffiliateConfig is not typed
        window.lemonSqueezyAffiliateConfig = { store: "docuchat" };
      },
    },
  ],
});

// SEO
useSeoMeta({
  title: "DocuChat",
  description: "Empower Your Business with Reliable AI Chatbots",
  themeColor: "#0E9384",
  ogImage: `${appUrl}/images/welcome.jpg`,
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#2ED3B7" />
    <UNotifications />

    <div>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>

    
  </div>
</template>

<style>
/* TODO: Bring back transitions once this resolves: https://github.com/vuejs/core/issues/8105 */
/* .slide-enter-active,
.slide-leave-active {
  transition: all 0.15s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
} */
</style>
